/*  로그인 창 */

.login-label {
    font-size: 14px;
    color: #566270;
}

.login-input {
    border: 0px;
    border-bottom: 1px solid #E0E3DA;
    height: 35px;
    margin-bottom: 10px
}

.login-button {
    border-radius: 5px;
    background-color: #30A9DE;
    color: #fff;
    border: 1px solid transparent;
}

.finding-label {
    color: #566270;
    font-size: 14px;
}

.line {
    margin: -1rem;
    height: 1rem;
}

.register-label {
    cursor: pointer;
    color: #566270;
}

/* 비밀번호 찾기 모달 */

.password-modal-input {
    border: 0px;
    border-bottom: 1px solid #E0E3DA;
    height: 35px;
    margin-bottom: 10px
}

.password-modal-btn {
    border-radius: 5px;
    background-color: #30A9DE;
    color: #fff;
}

/* 회원가입 모달 */

.register-modal-info {
    color: #566270;
    font-size: 12px
}

.register-modal-label {
    font-size: 14px;
    color: #566270;
}

.register-modal-input {
    border: 0px;
    border-bottom: 1px solid #E0E3DA;
    height: 35px;
    margin-bottom: 10px;
}

/* 화면 구성 */

.main-banner-container {
    background-color: #30A9DE;
    text-align: center;
    padding-top: 40px;
}

.login-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
    width: 1000px;
    margin: 0 auto;
}
.login-image-container {
    position: relative;
    border: 1px solid #E0E3DA;
    padding: 40px;
    width: 66%;
}
.login-image-text {
    position: relative;
    z-index: 5;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    font-weight: 100;
    font-size: 32px;
    line-height: 38px;
}
.login-image-color {
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    color:#30A9DE
}
.login-img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.main-step-container {
    background-color: #F3F4F5;
    padding: 50px 0px;
    text-align: center;
}
.main-step-text {
    font-size: 32px;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    font-weight: 700;
}

.main-qna-container {
    display: flex;
    justify-content: center;
    width: 1000px;
    margin: 0 auto;
    padding: 50px 0px;
}
.main-qna-text {
    font-size: 18px;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    padding: 30px 50px;
}
.main-qna-text-bold {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}
.main-qna-text-light {
    font-size: 16px;
    margin-top: 20px;
}

/* 회원가입 */

.signup-wrapper {
    background: #F3F4F5;
    height: 100%;
    width: 100%;
    padding: 30px 0px;
}
.signup-container {
    background: #fff;
    border-radius: 20px;
    width: 680px;
    padding: 40px;
    margin: 0 auto;
}
.signup-title {
    font-size: 24px;
    font-weight: 700;
    margin: 15px 0px 30px;
    text-align: center;
}
.signup-step-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.signup-step-circle {
    border-radius: 50%;
    background-color: #c4c4c4;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signup-step-circle.active {
    background-color: #30A9DE;
    color: #fff;
}
.signup-step-line {
    width: 76px;
    height: 1px;
    background: #c4c4c4;
    margin: 0px 11px
}
.signup-step-title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
}
.signup-step-box {
    border: 1px solid #c4c4c4;
    padding: 20px 25px;
    margin-bottom: 30px;
    line-height: 24px;
}
.signup-step-btn-container {
    text-align: center;
    margin-bottom: 30px;
}
.signup-step-btn {
    width: 250px;
    margin: 0 auto;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
}
.signup-step-img-container {
    text-align: center;
    margin-bottom: 20px;
}
.signup-step-img {
    width: 150px;
    margin: 0 auto;
}
.signup-input-container {
    width: 80%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
}
.signip-input-subtitle {
    margin-top: -20px;
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
}

/* 포인트 내역 */

.ra-point-container {
    display: flex;
    padding: 15px;
    align-items: center;
    flex-wrap: wrap;
}
.ra-point-container svg{
    padding-bottom: 3px;
}

/* 알림 모달 */

.notice-modal-container {
    min-height: 550px;
}

.notice-table-new {
    background: red;
    color: #fff;
    padding: 5px;
    margin-right: 5px;
    border-radius: 3px;
    font-size: 12px;
}